// import jquery from "jquery";
// import * as bootstrap from 'bootstrap';

window.writeMail = function writeMail(user, domain, tld, silver) {
    document.write(getMail(user, domain, tld, silver));
}

window.getMail = function getMail(user, domain, tld, silver) {
	return '<a href="' + 'mailto:' + user + '@' + domain + '.' + tld + '" class="plain">'
        + user + (silver ? '<span class="silver">@</span>' : '@') + domain + (silver ? '<span class="silver">.</span>' : '.') + tld
        + '</a>';
}
